<template>
  <div id="background" :style="{ backgroundImage: 'url(' + imagepath() + ')'  }">
    <Header color="primary"/>

    <div class="wrapper">
      <div id="content">
        <v-container>
          <v-row align="center" justify="center">
            <v-col class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '80px' }" xl="8" lg="10" md="12" sm="12"  xs="12">
              News
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-for="(obj,i) in blog" v-bind:key="i">
            <v-col md="10" lg="10" xl="8" sm="12" xs="12">
              <v-card elevation="0" outlined>
                <v-card-title :style="{ fontFamily: 'Raleway' }">
                  {{ obj.date }}
                </v-card-title>
                <v-card-text class="text-left" :style="{ fontFamily: 'Raleway' }">
                  {{ obj.text }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>

import sharedfunctions from "../../sharedfunctions";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

export default {
  name: "News",
  components: {Footer, Header},
  data: () => ({
    blog:[
      {date: '24. Januar 2023', text: 'Sollte der Parkplatz vor dem Gemeindehaus besetzt sein, kann bereits während der Trauung der Parkplatz des Hotel/Restaurant Sternen benutzt werden.'},
      {date: '23. Januar 2023', text: 'Die Trauung im Standesamt findet um 14:00 Uhr statt. Die Standesbeamtin wird die Gäste und die Trauzeugen um ca. 13:50 Uhr in das Trauungslokal führen, danach sind die Türen zu und ein nachträgliches Eintreffen ist leider nicht mehr möglich. Bitte versucht, bis 13:45 Uhr vor dem Gemeindehaus in Domat/Ems zu sein.'},
    ]
  }),
  methods: {
    getFontSize(isTitle = false) {
      if (this.isMobile()) {
        return isTitle ? 'medium' : 'small';
      } else {
        return isTitle ? 'x-large' : 'large';
      }
    },
    setBackgroundColor() {
      if (this.isMobile()) {
        return 'rgba(241,243,240,0.8)'
      } else {
        return 'rgba(241,243,240,0.8)'
      }
    },
    imagepath() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require("../../assets/backgrounds/background.jpg");
        case 'sm':
          return require("../../assets/backgrounds/background.jpg");
        case 'md':
          return require("../../assets/backgrounds/pexels-oscar-helgstrand-5957574.jpg");
        case 'lg':
          return require("../../assets/backgrounds/pexels-oscar-helgstrand-5957574.jpg");
        case 'xl':
          return require("../../assets/backgrounds/pexels-oscar-helgstrand-5957574.jpg");
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    initColorScheme() {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    },
    // eslint-disable-next-line no-unused-vars
    myEventHandler(e) {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    }
  },
  beforeMount(){
    this.$vuetify.theme.dark = false;
  },
  created(){
    window.addEventListener("resize", this.myEventHandler);
    this.isMobile = sharedfunctions.isMobile;
    this.updateDarkMode = sharedfunctions.updateDarkMode;
    this.initColorScheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
}
</script>

<style scoped>

</style>
